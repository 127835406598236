<template>
  <div>
    <div class="mt-4">
      <v-dialog v-model="showComprovante" width="700px">
        <v-card>
          <comprovante />
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDocumentos" width="700px">
        <v-card>
          <documentos />
        </v-card>
      </v-dialog>
      <div style="margin: 20px">
        <v-btn color="info" class="w-100 my-1" @click="getDownload">
          <v-icon class="mx-2" left dark> mdi-download </v-icon>
          Baixar Planilha</v-btn
        >
      </div>

      <v-data-table
        :headers="headersComputed"
        :items="senhasAtendimento"
        sort-by="descricao"
        class="border"
        :search="search"
        :server-items-length="senhasAtendimentoCount"
        :options.sync="options"
        @update:options="handleGetSenhas"
      >
        <template v-slot:[`item.sub_produto`]="{ item }">
          <div v-if="item.agendamento !== null" class="div-pedido-info">
            <p
              v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
              :key="index"
            >
              {{ pedido.subProduto.descricao }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.numero_pedido`]="{ item }">
          <div v-if="item.agendamento !== null" class="div-pedido-info">
            <p
              v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
              :key="index"
            >
              {{ pedido.numero_pedido }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          <div v-if="item.agendamento !== null" class="div-pedido-info">
            <p
              v-for="({ quantidade_agendada }, index) in item.agendamento
                .pedidos_agendados"
              :key="index"
            >
              {{ converteKg(quantidade_agendada) }}
            </p>
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Senhas de atendimento</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog_indicadores" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mr-2 mb-2" v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores :headers="headers" @close="close_indicadores" />
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on"
                  >Novo Atendimento</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Novo atendimento</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Placa Cavalo"
                          v-mask="['AAA-XXX', 'AAA-XXXX']"
                          ref="placa_cavalo"
                          v-model="editedItem.placa_cavalo"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save"
                    >Gerar Senha</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-card-title>
            Filtro
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisa"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-2 mb-2"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="handleVisualizarComprovante(item)"
                class="pointer"
                title="Visualizar Comprovante da Senha"
              >
                Comprovante da Senha
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="handleVisualizarDocumentos(item.agendamento)"
              >
                Documentos
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.atendido_em`]="{ item }">
          {{
            item.atendido_em ? new Date(item.atendido_em).toLocaleString() : '-'
          }}
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="handleGetSenhas">Reset</v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogDeleteItem" max-width="500px">
        <v-card>
          <v-card-title>
            <v-card-text>
              <h3 class="transition-swing text-h3 mb--1 error--text">
                Cuidado!
              </h3>
              <p class="mt-8">Tem certeza que deseja deletar este registro?</p>
            </v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="
                dialogDeleteItem = false
                itemDelete = {}
              "
            >
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="deleteItem()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import MotoristasApi from '@/services/tenants/motoristas.js'
import ContratoAPI from '@/services/tenants/contratos'
import { cpf } from 'cpf-cnpj-validator'
import TransportadorasApi from '@/services/tenants/transportadoras.js'
import PatioApi from '@/services/patio/index'
import Comprovante from '@/components/patio/SenhaAtendimento/comprovante/Componente.vue'
import Documentos from '@/components/patio/NovoAgendamento/documentos/Componente.vue'
import Indicadores from '@/components/patio/SenhaAtendimento/indicadores/Componente'
import { getUrl, downloadUrl } from '@/utils/index'

import { SENHA_ATENDIMENTO } from '@/utils/storages'

const LOCAL_STORAGE_ID = SENHA_ATENDIMENTO
export default {
  provide() {
    return {
      fecharComprovante: this.fecharComprovante,
      visualizarDocumentos: this.visualizarDocumentos,
      fecharDocumentos: this.fecharDocumentos,
    }
  },
  data() {
    return {
      showDocumentos: false,
      showComprovante: false,
      dialog: false,
      dialog_indicadores: false,
      selectFornecedor: [],
      transportadorasSelecionadas: [],
      motoristasSelecionados: [],
      motoristaVeiculo: [],
      selectGate: [],
      search: '',
      searchTransportadora: '',
      veiculosSelecionados: [],
      veiculoVeiculo: [],
      searchVeiculo: '',
      searchMotorista: '',
      veiculoText: '',
      transportadoraText: '',
      headers: [
        {
          habilited: true,
          block: true,
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
        { text: 'Sequência', value: 'sequencia', habilited: true, block: true },
        { text: 'Veículo', value: 'placa', habilited: true, block: true },
        {
          text: 'Data do Atendimento',
          value: 'atendido_em',
          habilited: true,
          block: true,
        },
        {
          habilited: true,
          block: false,
          text: 'Status',
          value: 'agendamento.status',
        },
        {
          habilited: false,
          block: false,
          text: 'Carreta 1',
          value: 'agendamento.placa_carreta_1',
        },
        {
          habilited: false,
          block: false,
          text: 'Carreta 2',
          value: 'agendamento.placa_carreta_2',
        },
        {
          habilited: false,
          block: false,
          text: 'Senha',
          value: 'agendamento.senhas',
        },
        {
          habilited: false,
          block: false,
          text: 'Tipo Operação',
          value: 'agendamento.tipo_operacao',
        },
        {
          habilited: false,
          block: false,
          text: 'Data da Cota',
          value: 'agendamento.data_cota',
        },
        {
          habilited: true,
          block: false,
          text: 'Data do Pedido',
          value: 'agendamento.data_pedido',
        },
        {
          habilited: false,
          block: false,
          text: 'Agendado Em',
          value: 'agendamento.agendado_em',
        },
        {
          habilited: true,
          block: false,
          text: 'Triado Em',
          value: 'agendamento.triado_em',
        },
        {
          habilited: true,
          block: false,
          text: 'Agendado por',
          value: 'agendamento.agendado_por',
        },
        {
          habilited: true,
          block: false,
          text: 'Tempo em pátio',
          value: 'agendamento.tempoEmPatio',
        },
        {
          habilited: false,
          block: false,
          text: 'Fornecedor',
          value: 'agendamento.fornecedor',
        },
        {
          habilited: true,
          block: false,
          text: 'SubProduto',
          value: 'agendamento.pedidos_agendados.sub_produto',
        },
        {
          habilited: true,
          block: false,
          text: 'Quantidade (KG)',
          value: 'agendamento.pedidos_agendados.quantidade',
        },
        {
          habilited: true,
          block: false,
          text: 'Embalagem',
          value: 'agendamento.pedidos_agendados.embalagem',
        },
        {
          habilited: false,
          block: false,
          text: 'Gate',
          value: 'agendamento.gate',
        },
        {
          habilited: true,
          block: false,
          text: 'Cliente',
          value: 'agendamento.cliente',
        },
        {
          habilited: false,
          block: false,
          text: 'Terminal',
          value: 'agendamento.terminal',
        },
        {
          habilited: false,
          block: false,
          text: 'Terminal Destino',
          value: 'agendamento.terminal_destino',
        },
        {
          habilited: false,
          block: false,
          text: 'Região',
          value: 'agendamento.regiao',
        },
        {
          habilited: false,
          block: false,
          text: 'Eixos',
          value: 'agendamento.eixos',
        },
        {
          habilited: false,
          block: false,
          text: 'Modalidade',
          value: 'agendamento.veiculo.modalidade.descricao',
        },
        {
          habilited: false,
          block: false,
          text: 'Carroceria',
          value: 'agendamento.veiculo.carroceria.descricao',
        },
        {
          habilited: false,
          block: false,
          text: 'Motorista',
          value: 'agendamento.motorista',
        },
        {
          habilited: false,
          block: false,
          text: 'N° Tel',
          value: 'agendamento.motorista_tel',
        },
        {
          habilited: false,
          block: false,
          text: 'N° Doc. Motorista',
          value: 'agendamento.motorista_doc',
        },
        {
          habilited: false,
          block: false,
          text: 'Proprietário',
          value: 'agendamento.proprietario',
        },
        {
          habilited: false,
          block: false,
          text: 'Transportadora',
          value: 'agendamento.transportadora',
        },
        {
          habilited: false,
          block: false,
          text: 'Nº Pedido',
          value: 'agendamento.pedidos_agendados.numero_pedido',
        },
        {
          habilited: false,
          block: false,
          text: 'Produto',
          value: 'agendamento.pedidos_agendados.produto',
        },
        {
          habilited: false,
          block: false,
          text: 'Návio',
          value: 'agendamento.navio',
        },
        {
          habilited: false,
          block: false,
          text: 'Tipo Frete',
          value: 'agendamento.tipo_frete',
        },
        { habilited: false, block: false, text: 'DI', value: 'agendamento.di' },
        {
          habilited: false,
          block: false,
          text: 'Nº Nota fiscal',
          value: 'agendamento.nota_fiscal',
        },
        {
          habilited: false,
          block: false,
          text: 'Píer',
          value: 'agendamento.pier',
        },
        {
          habilited: false,
          block: false,
          text: 'ID Bunge',
          value: 'agendamento.classifWebId',
        },
        {
          habilited: false,
          block: false,
          text: 'Status Integração',
          value: 'agendamento.statusMessage',
        },
        {
          habilited: false,
          block: false,
          text: 'Observação',
          value: 'agendamento.observacao',
        },
        {
          habilited: false,
          block: false,
          text: 'Umidade',
          value: 'agendamento.umidade',
        },
        {
          habilited: false,
          block: false,
          text: 'Remetente',
          value: 'agendamento.remetente',
        },
        {
          habilited: false,
          block: false,
          text: 'Destinatário',
          value: 'agendamento.destinatario',
        },
      ],
      editedIndex: -1,
      editedItem: {
        gate: null,
        placa_cavalo: null,
      },
      defaultItem: {
        gate: null,
        fornecedor: null,
        transportadora: null,
        veiculo: null,
        motorista: null,
        active: true,
        observacao: '',
        data: null,
      },
      dialogDeleteItem: false,
      itemDelete: {},
      options: { itemsPerPage: 10 },
    }
  },
  computed: {
    ...mapState('patio', ['senhasAtendimento', 'senhasAtendimentoCount']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('params', ['senha_atendimento']),
    params() {
      return this.senha_atendimento
    },

    headersComputed() {
      return this.headers
        .filter((item) => item.display !== false)
        .filter((header) => !!header.habilited)
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    async searchTransportadora(searchTransportadora) {
      if (searchTransportadora.length > 2 && searchTransportadora.length <= 5) {
        await this.getTransportadoraSearch({
          pesquisa: searchTransportadora,
        })
      } else if (searchTransportadora.length < 2) {
        this.transportadorasSelecionadas = []
      }
    },

    async searchVeiculo(searchVeiculo) {
      if (searchVeiculo.length > 2 && searchVeiculo.length <= 5) {
        await this.getVeiculoSearch(searchVeiculo)
      } else if (searchVeiculo.length < 2) {
        this.veiculosSelecionados = []
      }
    },

    async searchMotorista(searchMotorista) {
      if (searchMotorista.length > 2 && searchMotorista.length <= 6) {
        await this.getMotoristaSearch({
          pesquisa: searchMotorista,
        })
      } else if (searchMotorista.length < 2) {
        this.veiculosSelecionados = []
      }
    },
  },

  async created() {
    this.setIndicadores()
    await this.handleGetSenhas()

    if (this.empresaAtual.gate) {
      this.selectGate = [this.empresaAtual]
      this.editedItem.gate = this.empresaAtual

      this.selectedGate()
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.selectGate = [
        { business_name: 'Nenhum', cnpj: '0', public_id: null },
        ...possible_gates,
      ]
    }
  },

  methods: {
    ...mapMutations('agendamentos', [
      'ATUALIZA_AGENDAMENTO',
      'ATUALIZA_ATENDIMENTO',
    ]),
    ...mapActions('patio', [
      'getSenhasAtendimento',
      'getGerarSenhasAtendimento',
      'addSenhasAtendimento',
      'editSenhasAtendimento',
      'deleteSenhasAtendimento',
    ]),
    ...mapActions('fornecedores', [
      'getFornecedores',
      'getFornecedoresByParams',
    ]),
    ...mapActions('transportadoras', [
      'getTransportadoras',
      'getTransportadoraEspecifica',
    ]),
    setIndicadores() {
      const headersSaved = localStorage.getItem(LOCAL_STORAGE_ID.headers)

      const headersSavedParsed = JSON.parse(headersSaved)
      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const headers_saved_map_habilited = headersSavedParsed
          .filter((item) => item.habilited)
          .map((item) => item.value)
        const headers_saved_map_not_habilited = headersSavedParsed
          .filter((item) => !item.habilited)
          .map((item) => item.value)
        this.headers = this.headers.map((item) => {
          let habilited = false
          if (headers_saved_map_habilited.includes(item.value)) {
            habilited = true
          } else {
            if (headers_saved_map_not_habilited.includes(item.value)) {
              habilited = false
            } else {
              // Para os novos
              habilited = item.habilited
            }
          }
          return {
            ...item,
            habilited,
          }
        })
        localStorage.setItem(
          LOCAL_STORAGE_ID.headers,
          JSON.stringify(this.headers)
        )
      } else {
        localStorage.setItem(
          LOCAL_STORAGE_ID.headers,
          JSON.stringify(this.headers)
        )
      }
    },
    handleVisualizarDocumentos(agendamento) {
      this.ATUALIZA_AGENDAMENTO(agendamento)
      this.visualizarDocumentos()
    },
    visualizarDocumentos() {
      this.showDocumentos = true
    },
    fecharDocumentos() {
      this.showDocumentos = false
    },
    fecharComprovante() {
      this.showComprovante = false
    },
    handleVisualizarComprovante(item) {
      this.ATUALIZA_ATENDIMENTO(item)
      this.showComprovante = true
    },
    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.itemDelete = item
    },
    converteKg(item) {
      return (parseFloat(item) * 1000).toFixed(0)
    },
    async getDownload() {
      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()

        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes.map((regiao) => regiao.public_id)
          this.params.representante = this.user.public_id
        }
      }
      this.loading = true
      let data = []
      try {
        const indicadores = this.headersComputed
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')
        const path = 'api/v1/patio/sequenciaatendimento/download/'
        const params = {
          gate: this.params.gate
            ? this.params.gate
            : this.empresaAtual.public_id,
          ...this.params,
          indicadores,
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Não foi possível baixar a planilha. Tente novamente mais tarde.'
        )
      } finally {
        this.loading = false
      }
      return data
    },
    async handleGetSenhas() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let ordering
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        ordering = this.options.sortBy[0]
        ordering = this.options.sortDesc[0] ? '-' + ordering : ordering
        ordering = ordering.replaceAll('.', '__')
      }
      await this.getSenhasAtendimento({
        ...this.params,
        gate: this.empresaAtual.public_id,
        page_size: itemsPerPage > 0 ? itemsPerPage : 1000,
        page: page,
        ordering: ordering,
        //   fornecedor: this.empresaAtual.public_id,
        //   cliente: this.empresaAtual.public_id,
        //   transportadora: this.empresaAtual.public_id
      })
      this.loading = false
    },
    async getTransportadoraSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        const transportadora = await TransportadorasApi.getTransportadoraEspecifica(
          this.empresaAtual,
          parametros
        )

        this.transportadorasSelecionadas = transportadora
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    async getVeiculoSearch(placa_cavalo) {
      const veiculo = await PatioApi.getVeiculoParams({
        placa_cavalo: placa_cavalo,
      })
      this.veiculosSelecionados = veiculo
    },

    editItem(item) {
      this.editedIndex = this.Blacklists.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    async selectedGate() {
      await this.atualizarListaFornecedores()
    },

    async atualizarListaFornecedores() {
      try {
        if (!this.empresaAtual.gate) {
          const fornecedores = await this.getFornecedoresByParams({
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
            empresa: this.editedItem.gate,
          })
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...fornecedores,
          ]
        } else {
          const fornecedores = await this.getFornecedores()
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...fornecedores,
          ]
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },

    async getMotoristaSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        const motorista = await MotoristasApi.getMotorista(
          this.empresaAtual,
          parametros
        )

        this.motoristasSelecionados = motorista
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    motoristaText(item) {
      return `${item.user.name} - ${cpf.format(item.user.cpf)}`
    },

    async getInfo() {
      await this.getSenhasAtendimento({
        gate: this.empresaAtual.public_id,
      })
    },

    async deleteItem() {
      await PatioApi.deleteBlacklist(this.itemDelete.id)
      this.dialogDeleteItem = false
      this.perifericoDelete = {}
      await this.handleGetSenhas()
    },

    close_indicadores() {
      this.dialog_indicadores = false
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    async save() {
      const senha = await this.getGerarSenhasAtendimento({
        gate: this.empresaAtual.public_id,
        placa_cavalo: this.editedItem.placa_cavalo,
      })
      console.log(senha.value)
      this.handleVisualizarComprovante(senha)
      this.dialog = false
      setTimeout(() => {
        this.handleGetSenhas({
          gate: this.empresaAtual.public_id,
        })
      }, 300)
    },
  },
  components: {
    Comprovante,
    Documentos,
    Indicadores,
  },
}
</script>
